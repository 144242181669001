const data_line = {
  title: {
    text: '',
  },
  tooltip: {},
  xAxis: {
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  },
  yAxis: {
    type: 'value',
  },
  legend: {
    data: ['简历总数'],
  },
  grid: {
    top: 70,
    bottom: 50,
  },
  series: [
    {
      name: '简历总数',
      type: 'line',
      data: [
        2.6,
        5.9,
        9.0,
        26.4,
        28.7,
        70.7,
        175.6,
        182.2,
        48.7,
        18.8,
        6.0,
        2.3,
      ],
    },
  ],
}

const data_pillar = {
  title: {
    text: '柱状图',
  },
  legend: {
    data: ['销量'],
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  },
  yAxis: {
    type: 'value',
  },
  tooltip: {},
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130],
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(220, 220, 220, 0.8)',
      },
    },
  ],
}

export { data_line, data_pillar }
