<template>
  <div class="wrap">
    <div v-loading="loading">
      <div class="link" ref="link"></div>
    </div>
  </div>
</template>

<script>
import { mon_day_de, mon_day_dee } from '@/api/hr24_api/data_board.js'
import { data_line } from '@/utils/data_black.js'

let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/chart/bar')
require('echarts/lib/component/legend')
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
let link = null
export default {
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    link = echarts.init(this.$refs.link)
  },
  created() {
    let obj = {
      show_data: this.$route.query.src,
    }
    console.log(this.$route.query.name, '43t')
    if (this.$route.query.name == '1') {
      mon_day_dee(obj).then((res) => {
        if (!res.code) {
          this.getLink(res)
        }
      })
    } else {
      mon_day_de(obj).then((res) => {
        if (!res.code) {
          this.getLink(res)
        }
      })
    }
    // setTimeout(() => {}, 300);
  },

  methods: {
    getLink(res) {
      data_line.title['text'] = this.$route.query.title

      data_line.series = [
        {
          name: '数量',
          type: 'line',
          data: res.data,
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: {
                  //数值样式
                  color: 'black',
                  fontSize: 16,
                },
              },
            },
          },
        },
      ]
      data_line.xAxis['data'] = res.time_list
      link.setOption(data_line)
      this.loading = false
    },
  },
}
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.wrap > div {
  display: flex;
  height: 80vh;
  overflow: scroll;
}

.link {
  width: 80%;
  height: 400px;
  margin: 0 auto;
}
</style>
